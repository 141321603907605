/* You can add global styles to this file, and also import other style files */


html, body { height: 100%; }
body { margin: 0; font-family: 'Roboto', sans-serif; }

.mat-fab-bottom-right {
  right: 20px;
  bottom: 20px;
  position: fixed !important;
  z-index: 1;
}
.full-width {
  width: 80%;
}

// fix for labels of radio buttons
.mat-radio-label {
  white-space: inherit !important;
}

@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$candy-app-primary: mat-palette($mat-red);
$candy-app-accent:  mat-palette($mat-blue-grey);

// The warn palette is optional (defaults to red).
$candy-app-warn:    mat-palette($mat-orange);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($candy-app-theme);

$primary: mat-color($candy-app-primary);
$accent: mat-color($candy-app-accent);
$warn: mat-color($candy-app-warn);

a {
  color: $primary;
}
